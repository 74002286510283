import React, {useContext, useEffect, useState} from 'react';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import {apiUrl} from "../../config/api";
import 'leaflet/dist/leaflet.css';
import './Map.css';
import '../../utils/fix-map-icon';
import {SearchContext} from "../../contexts/search.context";
import {SimpleAdEntity} from "types";
import {SingleAd} from "./SingleAd";




export const Map = () => {
    const {search} = useContext(SearchContext);
    const [ads, setAds] = useState<SimpleAdEntity[]>([]);

    // useEffect(() => {
    //     console.log('Make request to search for', search)
    // }, [search]);

    useEffect(() => {
        (async () => {
            const res = await fetch(`${apiUrl}/ad/search/${search}`);
            const data = await res.json();
            console.log(data);
            setAds(data);
        })()
    }, [search]);

    return (
        <div className="map">
            {/*<h1>Search for: {search}</h1>*/}
            <MapContainer center={[52.2387456,21.0108416]} zoom={13}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> & contributors"
                />
                {
                    ads.map(ad => (
                        <Marker key={ad.id} position={[ad.lat, ad.lon]}>
                            <Popup>
                                <SingleAd id={ad.id}/>
                            </Popup>
                        </Marker>
                    ))
                }
                <Marker position={[52.2387456,21.0108416]}>
                    <Popup>
                        <h2>Warszawa</h2>

                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    );
}
